import * as React from "react";
import Layout from "../components/Layout";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect-1.svg";
import TunisiaJobs from "../assets/img/tunisia-jobs-5.jpg";
import TunisiaJobs2 from "../assets/img/tunisiaJobs5-2.jpg";
import TunisiaJobs3 from "../assets/img/tunisiaJobs5-3.png";
import TunisiaJobs4 from "../assets/img/tunisiaJobs5-4.png";
import helping from "../assets/img/helping.jpg";

const WorkforceDevelopment = () => (
  <Layout>
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-banneractivitiesworkforce"
            className="block block-block-content block-block-content9fca3b64-ffe8-4aa4-9c40-d2f1d5135d8d"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img src={BannerEffect} alt="" />
              </div>
              <img src={TunisiaJobs} alt="" />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    Workforce Development
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Preparing&nbsp;<span>Tunisian youth</span> for jobs in
                      demand by the private sector
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={23}
              role="article"
              about="/workforce-development"
              className="node node--type-activities node--promoted node--view-mode-full"
            >
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="sector-vision">
                      <div className="container">
                        <div className="row">
                          <div className="card-wrapper">
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              <div className="inner-card">
                                <h3>The Sector</h3>
                                <p>
                                  The private sector accounts for over
                                  two-thirds of Tunisia’s GDP.
                                </p>
                                <p>
                                  Key to Tunisian businesses is a workforce with
                                  the skills to meet their needs. But even when
                                  jobs are available, young people are not fully
                                  prepared for real workplace needs. While the
                                  government of Tunisia has invested heavily in
                                  university and vocational education, efforts
                                  to date have not been sufficient to prepare
                                  businesses’ future employees.
                                </p>
                              </div>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay
                            >
                              <div className="inner-card">
                                <h3>JOBS’ Vision and Strategy</h3>
                                <p>
                                  JOBS’ strategy is to promote and support
                                  public-private sector partnerships that join
                                  the supply (youth – higher education,
                                  vocational training) with the demand side
                                  (employers) of the WFD equation to ensure that
                                  WFD programs train youth for present and
                                  future jobs, thus helping to reduce
                                  unemployment.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="our-work">
                      <div className="brick brick--type--our-work-workforce brick--id--60 eck-entity container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Our Work
                          </div>
                        </h2>
                        <div className="aos-animate" data-aos="fade-up">
                          <p>
                            JOBS supports the Ministry of Higher Education and
                            Scientific Research (MHESR) and Ministry of
                            Vocational Training and Employment (MFPE) to
                            strengthen curricula reform, as well as individual
                            companies and sector federations across a variety of
                            areas, including job placement, curricula reform,
                            employment market information, and internships.
                          </p>
                          <p>
                            <span>Our work focuses on four critical area</span>
                          </p>
                        </div>
                        <div className="work-card-wrapper">
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={helping} alt="" />
                            <div className="about-work">
                              <h5>
                                Helping place youth in jobs that meet private
                                sector needs
                              </h5>
                              <p>
                                JOBS supports universities, vocational training
                                centers, individual companies, and sector
                                federations, to match job seekers with existing
                                vacancies, especially in JOBS’ client firms. We
                                provide training in technical and soft skills,
                                as well as creating and/or strengthening
                                internship programs and organizing employability
                                events (job fairs, career days, and networking
                                events). JOBS is also helping youth to access
                                online nationwide job search and orientation
                                tools.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={TunisiaJobs2} alt="" />
                            <div className="about-work">
                              <h5>
                                Strengthening career placement services at
                                universities and vocational training centers
                              </h5>
                              <p>
                                Career placement centers at universities (4Cs)
                                and vocational training centers (AREs) are the
                                linchpin between youth and future employers.
                                JOBS is helping to strengthen Tunisia’s
                                extensive but underdeveloped placement systems
                                by creating sustainable pilot programs in up to
                                five regional universities and 12 vocational
                                training centers (VTC) that can then be scaled
                                nationwide. Key to this effort is developing a
                                sustainable revenue base, alliances with the
                                private sector, and sound governance.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={TunisiaJobs3} alt="" />
                            <div className="about-work">
                              <h5>
                                Bolstering academic curricula to better prepare
                                youth for the job market
                              </h5>
                              <p>
                                As part of JOBS’ support for Tunisia’s
                                first-ever public-private collaboration to
                                reform undergraduate curricula nationally, it
                                organized a series of workshops with
                                representatives of the private sector and the
                                National Sectoral Commission to incorporate
                                training needed in the private sector into
                                higher education curricula.
                              </p>
                              <p>
                                As a result, more than 90 universities in 20
                                regions implemented new curricula for 12
                                different university degrees. This will better
                                prepare more than 9,000 students for the
                                workforce each year.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={TunisiaJobs4} alt="" />
                            <div className="about-work">
                              <h5>Enhancing career information systems</h5>
                              <p>
                                JOBS is working with MFPE and professional
                                associations to digitalize course content in
                                priority sectors (textile, tourism, and
                                agriculture), expanding access to educational
                                resources and tools. JOBS is helping MFPE
                                integrate soft skills into educational curricula
                                with private sector input in priority sectors.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="impact aos-animate" data-aos="fade-up">
                      <div className="brick brick--type--impact brick--id--13 eck-entity container">
                        <span className="title-left">
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Impact To Date
                          </div>
                        </span>
                        <h2></h2>
                        <ul className="pl-0">
                          <li>
                            <span>
                              <span>
                                3,355 youth matched to new jobs with JOBS client
                                firms
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                23 curricula reformed and designed with private
                                sector input impacting 24,000 students
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Over 300 teachers and career center staff at
                                universities and vocational training centers
                                trained to prepare youth for new jobs
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                14 vocational training courses digitalized, in
                                partnership with the MHESR and Vocational
                                Training Centers
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Facilitated 18 VTCs in 9 regions to implement
                                public-private partnership councils
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Piloting a governance and business model for
                                university career centers, in up to five
                                regional universities, impacting 45% of the
                                total student enrolment in higher education in
                                the country
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Upgrading 4c.tn and expanding it to inform youth
                                about academic and career options, internships,
                                and job opportunities
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);

export default WorkforceDevelopment;
